import React, {useState, useRef, useEffect} from "react";
import './SketchfabViewer.css';
import {ChangeCommand, IMapTableRecord, IModel} from "./App";
import {ConstructNameToInstanceID, Constructs, LevelViews, SketchFabViewerUtils} from "./SketchFabViewerUtils";
import {ISelectedOption} from "./OptionSelector";
import {Simulate} from "react-dom/test-utils";
import change = Simulate.change;

export interface INode {
    children: INode[];
    instanceID: number;
    name: string;
    materialID: string;
}

export interface ICamera {
    name: string;
    origin: number[];
    target: number[];
}

export interface ISketchfabViewerApi{
    hide(i :number) : void;
    show(i :number) : void;
    start(): void;
    addEventListener(event: string, func: any): void;
    getSceneGraph(func: any) : void;
    getNodeMap(func: any) : void;
    getTextureList(func: any) : void;
    getMaterialList(func: any) : void;
    assignMaterial(myNode: any, myMaterialID: any, func: any) : void;
    setMaterial(material: any, func: any) : void;
    setCameraLookAt(position: number[], target: number[]) : void;
    setWireframe(enable: boolean,  func: any): void;
}

interface ISketchFabViewerProps {
    changeCommands: ChangeCommand[] | undefined;
    model: IModel;
    mapTable: IMapTableRecord[] | undefined;
    //selectedOptions: ISelectedOption[] | undefined;
}

function moveCamera(camera: ICamera, api: ISketchfabViewerApi | undefined){
    if(api) {
        api.setCameraLookAt(camera.origin, camera.target)
    }
}


function ApplyCommand(changeCommand: ChangeCommand, api: any, nodeMap: any, guidToObjectIDMap : Map<string, number>){
    //get sketchfab objectID from guid
    let instanceID = changeCommand.sketchfabInstanceID ? changeCommand.sketchfabInstanceID : guidToObjectIDMap.get(changeCommand.objectGUID);

    if(!instanceID){
        return;
    }

    changeCommand.isShow ? api.show(instanceID) : api.hide(instanceID);
    if(changeCommand.textureUID && changeCommand.textureUID.length > 0){
        let node = nodeMap[instanceID];
        //let materialID = node.materialID;
        //let material = materials.find((material : any) => material.id === materialID);
        api.assignMaterial(node, changeCommand.textureUID, function(err : any) {
            if (!err) {
                window.console.log('Material assigned');
            }
        });
    }
}

function applyCommands(changeCommands: ChangeCommand[], api: any, nodeMap: any, guidToObjectIDMap : Map<string, number>){
    changeCommands.forEach((command : ChangeCommand) => {
        ApplyCommand(command, api, nodeMap, guidToObjectIDMap);
    })
}

function SketchfabViewer(props: ISketchFabViewerProps) {
    const { changeCommands, model, mapTable } = props;

    const [api, setApi] = useState<ISketchfabViewerApi | undefined>(undefined);
    const [graph, setGraph] = useState<object | undefined>(undefined);
    const [nodeMap, setNodeMap] = useState<any | undefined>(undefined);
    const [textures, setTextures] = useState<Map<any, any> | undefined>(undefined);
    const [materials, setMaterials] = useState<any[] | undefined>(undefined);
    const [guidToObjectIDMap, setGuidToObjectIDMap] = useState<Map<string, number> | undefined>(undefined);
    const [viewsToObjectIDsMap, setViewsToObjectIDsMap] = useState<Map<string, number[]> | undefined>(undefined);
    const [constructToInstanceIDMap, setConstructToInstanceIDMap] = useState<ConstructNameToInstanceID[] | undefined>(undefined);
    const [constructVisibilities, setConstructVisibilities] = useState<Map<string, boolean> | undefined>(undefined);
    const [objectGUID, setObjectGUID] = useState<string>("");
    const [sketchfabInstanceID, setSketchfabInstanceID] = useState<number | undefined>(undefined);
    const [materialUID, setMaterialUID] = useState<string>("");
    const [isShow, setIsShow] = useState<boolean>(true);

    const viewerIframeRef = useRef(null);


    const changeConstructVisibility = (construct: ConstructNameToInstanceID, api: ISketchfabViewerApi | undefined) => {
        if(api && constructVisibilities){
            let newConstructVisibilities = new Map<string, boolean>();
            constructVisibilities.forEach((value: boolean, key: string) => {
               if(key == construct.name){
                   newConstructVisibilities.set(key, !value);
                   SketchFabViewerUtils.showOrHideObjcects([construct.id], api, !value);
               }else{
                   newConstructVisibilities.set(key, value);
               }
            });

            setConstructVisibilities(newConstructVisibilities);
        }
    }


    const applyClick = () => {
        ApplyCommand(new ChangeCommand(objectGUID, isShow, materialUID, sketchfabInstanceID), api, nodeMap, guidToObjectIDMap!);
    }


    const groupIdChange = (event : any) => {
        setObjectGUID(event.target.value);
    }
    const materialUIDChange = (event : any) => {
        setMaterialUID(event.target.value);
    }
    const sketchfabInstanceIDChange = (event : any) => {
        setSketchfabInstanceID(event.target.value);
    }
    const isShowChange = (event : any) => {
        setIsShow(event.target.checked);
    }



    useEffect(() => {
        //
        let client = new (window as any).Sketchfab(viewerIframeRef.current);

        client.init(model.sketchfabModelId, {
            success: function onSuccess(api: ISketchfabViewerApi) {

                api.start();
                api.addEventListener('viewerready', () => {

                    api.getSceneGraph(function (err: any, result: any) {
                        if (err) {
                            console.log('Error getting nodes');
                            return;
                        }
                        setGraph(result);
                        console.log(result);
                    });

                    api.getNodeMap((err: any, result: any) => {
                        if (err) {
                            console.log('Error getting nodes');
                            return;
                        }
                        setNodeMap(result);
                        console.log(result);
                    });

                    api.getTextureList((err: any, textures: any) =>  {
                        if (!err) {
                            window.console.log(textures);
                            setTextures(textures);
                        }
                    });

                    api.getMaterialList((err: any, materials: any) =>  {
                        if (!err) {
                            window.console.log(materials);
                            setMaterials(materials);
                        }
                    });
                    // API is ready to use
                    // Insert your code here
                    console.log('Viewer is ready');
                    let frontCamera = model.cameras.find(c => c.name === "Front");
                    if(frontCamera) {
                        moveCamera(frontCamera, api);
                    }
                    (window as any).sketsfap = api;

                    setApi(api);

                    //api.setWireframe(true, () => {});

                });

                api.addEventListener(
                    'click',
                    function(info: any) {
                        window.console.log('click at', info.position2D);
                        if (info.instanceID) {
                            // Hit
                            window.console.log('clicked node', info.instanceID);
                        }
                    }
                );
            },
            error: function onError() {
                alert('error');
                console.log('Viewer error');
            }
        });
    }, []);

    useEffect(() => {
        if(nodeMap && api && mapTable) {
            const [guidToObjectIDMap, constructToObjectIDsMap] = SketchFabViewerUtils.GetGuidToInstanceIDMap(nodeMap);

            const constructsToObjectInstanceIDMap = SketchFabViewerUtils.GetConstructsToInstanceIDMap(graph);
            console.log(constructsToObjectInstanceIDMap);

            setConstructToInstanceIDMap(constructsToObjectInstanceIDMap);
            let constructVisibilities = new Map<string, boolean>();
            for(let constructNameToInstanceID of constructsToObjectInstanceIDMap){
                constructVisibilities.set(constructNameToInstanceID.name, true);
            }
            setConstructVisibilities(constructVisibilities);


            setGuidToObjectIDMap(guidToObjectIDMap);
            setViewsToObjectIDsMap(constructToObjectIDsMap);

            console.log(guidToObjectIDMap);
            console.log(constructToObjectIDsMap);
        }
    }, [nodeMap])

    useEffect(() => {

        if(api && nodeMap && changeCommands && guidToObjectIDMap) {
            applyCommands(changeCommands, api, nodeMap, guidToObjectIDMap);
        }
    }, [changeCommands, api, guidToObjectIDMap])

    return (
        <div className="SketchfabViewer">
            <header className="SketchfabViewer-header">

                <div>
                SketchfabViewer
                </div>
                <div>
                    <iframe ref={viewerIframeRef} style={{width: "100%", height:"calc(100vh - 300px)"}} >
                    </iframe>
                </div>
                Cameras
                <div>
                    {
                        model.cameras.map((camera: ICamera) => {
                            return <button key={camera.name} value={camera.name} onClick={() => moveCamera(camera, api)}>{camera.name}</button>
                        })
                    }
                </div>
                Constructs
                <div>
                    {
                        constructToInstanceIDMap && constructVisibilities && constructToInstanceIDMap.map((constructToId: ConstructNameToInstanceID) => {
                            let cssClass = constructVisibilities.get(constructToId.name) ? "selected" : "unselected";
                            return <button className={cssClass}
                                            key={constructToId.name} value={constructToId.name}
                                           onClick={() => changeConstructVisibility(constructToId, api)}>{constructToId.name}</button>
                        })
                    }
                </div>
                {false && <div>
                Views (WIP)
                <div>
                    <button value="1" onClick={() => SketchFabViewerUtils.showConstructs(LevelViews.WHOLE_HOUSE, api, viewsToObjectIDsMap)}>Whole House</button>
                    <button value="2" onClick={() => SketchFabViewerUtils.showConstructs(LevelViews.SECOND_FLOOR, api, viewsToObjectIDsMap)}>2nd Floor</button>
                    <button value="3" onClick={() => SketchFabViewerUtils.showConstructs(LevelViews.FIRST_FLOOR, api, viewsToObjectIDsMap)}>1st Floor</button>
                    <button value="4" onClick={() => SketchFabViewerUtils.showConstructs(LevelViews.BASEMENT, api, viewsToObjectIDsMap)}>Basement/Foundation</button>

                </div>
                <div>
                    <label>
                        Geometry ID:
                        <input type={"text"} value={objectGUID} onChange={groupIdChange}/>
                    </label>
                </div>
                <div>
                    <label>
                        Texture UID
                        <input type={"text"} value={materialUID} onChange={materialUIDChange}/>
                    </label>
                </div>
                <div>
                    <label>
                        Sketcfab InstaceID
                        <input type={"number"} value={sketchfabInstanceID} onChange={sketchfabInstanceIDChange}/>
                    </label>
                </div>
                <div>
                    <label>
                        Show:
                        <input type={"checkbox"} checked={isShow} onChange={isShowChange}/>
                    </label>
                </div>
                <div>
                    <button onClick={applyClick}>Apply</button>
                </div>

                Graph
                <div className="debug">
                    {/*nodeMap && SketchFabViewerUtils.getNodeMapStr(nodeMap)*/}
                </div>
                Materials
                <div className="debug">
                    {false && materials && SketchFabViewerUtils.getMaterialsStr(materials)}
                </div>
                Textures
                <div className="debug">
                    {false && textures && SketchFabViewerUtils.getTexturesStr(textures)}
                </div>
                </div>}
            </header>
        </div>
    );
}


export default SketchfabViewer;