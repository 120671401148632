import React, {useEffect, useState} from "react";
import './OptionSelector.css';
import {ChangeCommand, IMapTableRecord, IModel} from "./App";
import {Client, JSGetOptContext} from "OptConfigurator";
import OptionList from "./OptionList";
import {KovaConfigurator} from "@bimaire/optionslib-kova";
import {IConfiguratorProxy, IMenuWithItems, IOptionItemAvailableChoice, IOptionItemChoice} from "@bimaire/optionslib";
import {Drawer} from "@mui/material";
import {OptionMenu} from "@bimaire/react-lib";
import {IOptSelInfo} from "@bimaire/optionslib-kova/build/loaders/optSelInfo";
import {OptEngineWrapper} from "@bimaire/optengine";
import {IStringVector} from "@bimaire/optengine/build/optengine_loader";

interface IOptionSelectorProps {

    applyOptions(options : IOptionItemChoice[]) : void;
    model: IModel;
    optionEngine: OptEngineWrapper;
    mapTable: IMapTableRecord[];
}

export interface ISelectedOption{
    optSelID: string;
    optValID: string;
}

function OptionSelector(props: IOptionSelectorProps) {

    const { applyOptions, model, optionEngine, mapTable } = props;

    const [currentConfigurator, setCurrentConfigurator] = useState<IConfiguratorProxy | null>(null);
    const [currentMenu, setCurrentMenu] = useState<IMenuWithItems | null>(null);

    const handleMenuItemClick = (menu: IMenuWithItems) => {
        setCurrentMenu(menu);
        //TODO menu is optval, there might be several choices
        const choice = currentConfigurator!.getAllChoices().find(ch => ch.items.findIndex(i => i.item.id === menu.id) >= 0);
        if (choice !== undefined) {
            let conf = currentConfigurator!.applyChoice({price: null, quantity: 1, choice: choice});
            setCurrentConfigurator(conf);

            let selectedChoices = conf.getChoices();
            applyOptions(selectedChoices);
        }
    };



    useEffect(() => {
        const loadOptContext = async () => {
            const data = await fetch(model.optContextPath);
            const respJson = await data.json();
            const state = respJson.source ? Client.loadFromJSON(respJson.source as JSGetOptContext) :  Client.loadFromJSON(respJson as JSGetOptContext);

            //get all optsels from conditions and hide other options
            let conditionContext = optionEngine.getJsOptContext();
            let optSelIDs = [];
            for(let mapTableRecord of mapTable){
                let jsConditionString = conditionContext.addCondition(mapTableRecord.OptionString);
                let tokens: IStringVector = jsConditionString.getOptionIdentifiers();
                for(let i = 0; i < tokens.size(); i++){
                    optSelIDs.push(tokens.get(i));
                }
            }

            let uniqueOptSelIDs = optSelIDs.filter((v, i, a) => a.indexOf(v) === i);
            let optSelInfo = uniqueOptSelIDs.map(uniqueOptSelID => {return {id: uniqueOptSelID}}) as IOptSelInfo[];

            const kovaConfigurator = new KovaConfigurator(optSelInfo);

            let conf = kovaConfigurator.getConfiguratorProxy(state) as IConfiguratorProxy;
            setCurrentConfigurator(conf);
            //apply defaults
            applyOptions(conf.getChoices());
        }

        loadOptContext();
    }, [])


    if (!currentConfigurator) return <div>Loading...</div>;

    let menus = currentConfigurator.getMenusWithItems();
    const menuOptSels = menus.map(m => m.id);
    var selectedChoices = currentConfigurator.getChoices().filter(ch => menuOptSels.includes(ch.choice.items[0].item.id));

    return (
        <div className="OptionSelector">

            <Drawer

                sx={{
                    width: 400,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: 400,
                        boxSizing: 'border-box',
                        background: '#F5F5F5'
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <OptionMenu items={menus} handleSelection={handleMenuItemClick} selectedChoices={selectedChoices} />
            </Drawer>
        </div>
    );
}

export default OptionSelector;