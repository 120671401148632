import validator from 'validator';
import {INode, ISketchfabViewerApi} from "./SketchfabViewer";
import {IMapTableRecord} from "./App";


class Constructs
{
    static ROOF = "roof";
    static SECOND_FLOOR = "2ndfloor";
    static FIRST_FLOOR = "1stfloor";
}

class LevelViews{
    static WHOLE_HOUSE = "wholehouse";
    static SECOND_FLOOR = "2ndfloor";
    static FIRST_FLOOR = "1stfloor";
    static BASEMENT = "basement";

}

export class ConstructNameToInstanceID{
    constructor(name: string, id:number) {
        this.name = name;
        this.id = id;
    }
    name: string;
    id: number

}

class SketchFabViewerUtils{

    static GetConstructsToInstanceIDMap(graph : any) : ConstructNameToInstanceID[] {
        let constructToInstanceIDMap = [];

        let root = graph;
        while(root.children.length == 1){
            root = root.children[0];
        }

        for(let node of root.children){
            let parsedName = node.name.substring(0, node.name.lastIndexOf('_'));
            constructToInstanceIDMap.push(new ConstructNameToInstanceID(parsedName, node.instanceID));
        }

        return constructToInstanceIDMap;
    }


    static GetGuidToInstanceIDMap(nodeMap : any) : [Map<string, number>, Map<string, number[]>] {
        let guidToObjectIDMap = new Map<string, number>();
        let constructToObjectIDsMap = new Map<string, number[]>();
        constructToObjectIDsMap.set(Constructs.ROOF, []);
        constructToObjectIDsMap.set(Constructs.SECOND_FLOOR, []);
        constructToObjectIDsMap.set(Constructs.FIRST_FLOOR, []);

        Object.entries(nodeMap).forEach(([key, value]) => {
            //sketchfab apparently assigns _XXX postfix to node names
            let node = value as INode;
            if(node.name) {
                let parsedGuid = node.name.substring(0, node.name.indexOf('_'));
                if (validator.isUUID(parsedGuid)) {
                    guidToObjectIDMap.set(parsedGuid, node.instanceID);
                }

                if(node.name.startsWith("Roof_") || node.name.startsWith("2nd Floor - Ceiling_")){
                    constructToObjectIDsMap.get(Constructs.ROOF)!.push(node.instanceID);
                }
                else if(node.name.startsWith("2nd Floor_") || node.name.startsWith("2nd Floor - Framing_") || node.name.startsWith("1st Floor - Ceiling_")){
                    constructToObjectIDsMap.get(Constructs.SECOND_FLOOR)!.push(node.instanceID);
                }
                else if(node.name.startsWith("1st Floor_") || node.name.startsWith("1st Floor - Framing_") || node.name.startsWith("Foundation - Ceiling_")){
                    constructToObjectIDsMap.get(Constructs.FIRST_FLOOR)!.push(node.instanceID);
                }
            }
        })
        return [guidToObjectIDMap, constructToObjectIDsMap];
    }


    static getMaterialsStr(materials : any){
        let materialJSON = materials.map((material : any) => {
            return {id: material.id, textureUID: material.channels.AlbedoPBR.texture ? material.channels.AlbedoPBR.texture.uid : "N/A"}
        })
        return JSON.stringify(materialJSON);
    }

    static getTexturesStr(textures : any){
        let textureJSON = textures.map((texture : any) => {
            return {name: texture.name, uid: texture.uid}
        })
        return JSON.stringify(textureJSON);
    }

    static getNodeMapStr(nodeMap: any[]){
        let nodes : any[] = [];
        Object.keys(nodeMap).forEach((key : any) =>{
            nodes.push({name: nodeMap[key].name, instanceID: nodeMap[key].instanceID});
        })
        return JSON.stringify(nodes);
    }

    static showConstructs(construct: string, api: ISketchfabViewerApi | undefined, constructToObjectIDsMap: Map<string, number[]> | undefined){
        if(api && constructToObjectIDsMap) {

            let levelVisibilities: boolean[] = [];

            if(construct === LevelViews.WHOLE_HOUSE){
                levelVisibilities = [true, true, true];
            }
            else if(construct == LevelViews.SECOND_FLOOR){
                levelVisibilities = [false, true, true];
            }
            else if(construct == LevelViews.FIRST_FLOOR){
                levelVisibilities = [false, false, true];
            }
            else if(construct == LevelViews.BASEMENT){
                levelVisibilities = [false, false, false];
            }

            this.showOrHideObjcects(constructToObjectIDsMap.get(Constructs.ROOF)!, api, levelVisibilities[0]);
            this.showOrHideObjcects(constructToObjectIDsMap.get(Constructs.SECOND_FLOOR)!, api, levelVisibilities[1]);
            this.showOrHideObjcects(constructToObjectIDsMap.get(Constructs.FIRST_FLOOR)!, api, levelVisibilities[2]);
        }
    }

    static showOrHideObjcects(objectIds: number[], api : ISketchfabViewerApi |undefined, isShow: boolean){
        if(!api){
            return;
        }
        objectIds.forEach((objectId: number) =>
            {
                isShow ? api.show(objectId) : api.hide(objectId);
            }
        )
    }
}

export {SketchFabViewerUtils, LevelViews, Constructs}
